import { useEffect, useRef, useState, useMemo, useCallback, FC } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { headerOptionsState, userToken } from '../atoms/appstate';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import animationData from '../assets/lottie/splash.json';
import { useNavigate } from 'react-router-dom';
// 바텀시트
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet-updated';
import {
	hexToRgb,
	RGB,
	rgbToHex,
	textColorFromBg,
	yiqColor,
} from '../libs/colors';
import HuePickColorPicker, {
	PickEvent,
} from '../components/HuePickColorPicker';
import 'react-spring-bottom-sheet-updated/dist/style.css';
import '../styles/ColorPicker.css';
import axios from '../config/axios';
import Konva from 'konva';
import { v4 as uuidv4 } from 'uuid';
import { dataURLToBlob } from '../libs/image';
// 아이콘, 이미지
import SvgIcSummer from '../components/icons/IcSummer';
import SvgHummingBird from '../components/icons/HummingBird';
import SvgIcThermo from '../components/icons/IcThermo';
import SvgIcBrightness from '../components/icons/IcBrightness';
import SvgIcSpring from '../components/icons/IcSpring';
import { SvgIconProps } from '../components/icons/icon';
import SvgIcFall from '../components/icons/IcFall';
import SvgIcWinter from '../components/icons/IcWinter';
import PalettePng from '../assets/palette.png';
import Lottie from 'react-lottie';
// 허밍버드 삭제 아이콘
import SvgCloseIcon from '../components/icons/SvgCloseIcon';
// 선호도 및 카테고리 UI
import { useRecoilState } from 'recoil';
import { sliderState } from '../atoms/sliderState';
// png 이미지 0704
import IcBeauty from '../assets/icons/Beauty_filled_on.png';
import IcFashion from '../assets/icons/Fashion_filled_on.png';
import IcInterior from '../assets/icons/Interior_filled_on.png';
import IcLife from '../assets/icons/Life_filled_on.png';
import IcProducts from '../assets/icons/Products_filled_on.png';
import IcEtc from '../assets/icons/Etc_filled_on.png';
import Cookies from 'js-cookie';
// 공용모달 개스트 로그인 유도
import PublicModal from '../components/modal/PublicModal';

export type ColorInfo = {
	uuid: string;
	originHexVal: string;
	hex: string;
	rgb: RGB;
	name: string;
	tones: {
		tone: string;
		season: string;
		detail: string;
	};
	matchingRatio?: any;
};

const defaultColorInfo: ColorInfo = {
	uuid: '',
	originHexVal: '',
	hex: '',
	rgb: { r: 0, g: 0, b: 0 }, // RGB 타입에 맞는 기본값
	name: '',
	tones: {
		tone: '',
		season: '',
		detail: '',
	},
	matchingRatio: undefined,
};

export interface GetParams {
	userId: string;
	userEmail: string;
}

interface PickerJson {
	uuid: string;
	r: number;
	g: number;
	b: number;
	exif: {
		latitude: number;
		longitude: number;
		[key: string]: any; // 추가적인 EXIF 데이터를 허용
	};
	token?: string; // token 속성은 선택적입니다.
}

function ColorPicker() {
	// 선호도 상태관리
	const [sliderValue, setSliderValue] = useRecoilState(sliderState);
	const [selected, setSelected] = useState<string>('');

	const categories = [
		{ name: 'Beauty', icon: IcBeauty },
		{ name: 'Fashion', icon: IcFashion },
		{ name: 'Interior', icon: IcInterior },
		{ name: 'Life', icon: IcLife },
		{ name: 'Products', icon: IcProducts },
		{ name: 'Etc', icon: IcEtc },
	];

	const handleSelect = (category: string) => {
		setSelected(category); // 선택된 항목 상태 업데이트
	};

	const sliderStyle = {
		background: `linear-gradient(to right, #c6bcff 0%, #4430bd ${sliderValue}%, #ddd ${sliderValue}%, #ddd 100%)`,
	};

	const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = Number(event.target.value); // Number로 변경
		if (newValue < 30) {
			setSliderValue(30); // 최소값을 30으로 설정
		} else {
			setSliderValue(newValue);
		}
	};

	const { t } = useTranslation();
	const timerRef = useRef<NodeJS.Timeout | null>(null);
	const token = Cookies.get('authToken');

	// const exifData = localStorage.getItem('exif');
	// exifData);

	const navigate = useNavigate();
	const [isModal, setIsModal] = useState(false);
	const sheetRef = useRef<BottomSheetRef>(null);
	const imageSrc = localStorage.getItem('blobImage'); // 화질 압축시켜서 세션에 올린 base 64 이미지파일
	const [imageBlob, setImageBlob] = useState<Blob | null>(
		dataURLToBlob(imageSrc),
	);
	const [open, setOpen] = useState<boolean>(false);
	const [isBlinking, setIsBlinking] = useState(false);
	// arler알림 대체 예외처리 안내 상태 메세지
	const [notification, setNotification] = useState('');

	const showNotification = (message: string) => {
		setNotification(message);
		setTimeout(() => {
			setNotification('');
		}, 1000); //
	};

	// const [isPickVisible, setIsPickVisible] = useState(true);
	const [pickedColors, setPickedColors] = useState<Array<string>>([]);

	const [prevPickedColorLength, setPrevPickedColorLength] = useState(-1);

	const getColorInfoFromSession = (): ColorInfo => {
		const storedData = localStorage.getItem('res-ColorInfo');
		if (storedData) {
			try {
				const parsedData: ColorInfo = JSON.parse(storedData);
				// 세션 스토리지의 데이터를 사용하되, hex 값만 조건적으로 수정하고 싶은 경우,
				// 여기에서 parsedData의 hex 값을 조정하거나 다른 필드를 수정할 수 있습니다.
				return parsedData; // 세션에서 가져온 데이터를 반환
			} catch (error) {
				console.error('Failed to parse stored color info:', error);
			}
		}
		// 세션 스토리지에서 유효한 데이터를 가져오지 못했거나, 데이터가 없는 경우 기본값 반환
		return defaultColorInfo;
	};
	const [colorInfo, setColorInfo] = useState<ColorInfo>(
		getColorInfoFromSession,
	);

	const [isLoading, setIsLoading] = useState(false);
	const [colorPaletteInfoActive, setColorPaletteInfoActive] = useState(false);
	const [touchPosition, setTouchPosition] = useState({ x: 0, y: 0 });

	useEffect(() => {
		const updatePosition = () => {
			const x = window.innerWidth / 2;
			const y = window.innerHeight / 2;
			setTouchPosition({ x, y });
		};

		updatePosition();
	}, []);

	const userTokenVal = useRecoilValue(userToken);

	const [analyzeResult, setAnalyzeResult] = useState({
		color: '#ffffff',
	});

	const handleRedirect = () => {
		navigate('/signin'); // Redirect to signin page on modal confirmation
	};

	const setHeaderOptions = useSetRecoilState(headerOptionsState);

	const detailName = (detail: string) => {
		if (
			detail === 'vp' ||
			detail === 'p' ||
			detail === 'lt' ||
			detail === 'b'
		) {
			return t('main:light');
		} else if (detail === 'b' || detail === 'v') {
			return t('main:bright');
		} else if (
			detail === 'ltg' ||
			detail === 'sf' ||
			detail === 'dl' ||
			detail === 'g'
		) {
			return t('main:mute');
		} else if (
			detail === 'dk' ||
			detail === 'dp' ||
			detail === 'dkg' ||
			detail === 'bk'
		) {
			return t('main:dark');
		}
	};

	const icSeasonal = (season: string, props: SvgIconProps) => {
		if (season === 'SPRING') {
			return SvgIcSpring(props);
		} else if (season === 'SUMMER') {
			return SvgIcSummer(props);
		} else if (season === 'FALL' || season === 'AUTUMN') {
			return SvgIcFall(props);
		} else if (season === 'WINTER') {
			return SvgIcWinter(props);
		}

		return null;
	};

	const handleOffModal = () => {
		setIsModal(false);
	};

	useEffect(() => {
		if (!imageSrc) {
			navigate('/upload');
		} else {
			createThumbnail(imageSrc); // 이전에 클롭이미지때문에 존재한다고 생각 2.13
		}
		// 헤더 설정 리코일
		setHeaderOptions((prev) => ({
			...prev,
			showBackward: true,
			showHeader: true,
			template: () => <span className="header-title-white">HuePick</span>,
		}));
		const storedData = localStorage.getItem('res-ColorInfo');
		if (storedData) {
			const parsedData = JSON.parse(storedData);
			const { uuid, originHexVal, hex, name, tones, matchingRatio } =
				parsedData;

			// hex 값을 이용하여 RGB 값 계산
			const rgb = hexToRgb(hex);

			if (rgb) {
				// 모든 필요한 정보로 새로운 ColorInfo 객체를 생성
				const newColorInfo: ColorInfo = {
					uuid,
					originHexVal,
					hex,
					rgb,
					name,
					tones,
					matchingRatio,
				};

				setColorInfo(newColorInfo);
			} else {
				console.error(
					'Invalid or incomplete data:',
					hex,
					matchingRatio,
				);
			}
		}
	}, []);

	useEffect(() => {
		if (pickedColors.length > 0) {
			setIsBlinking(true);
			const timer = setTimeout(() => {
				setIsBlinking(false);
			}, 2500); // 2.5초 후 깜빡임 효과를 중지합니다.
			return () => clearTimeout(timer);
		}
	}, [pickedColors]);

	// useEffect(() => {
	// 	const fileRef = useRef<HTMLInputElement | null>(null);

	// 	const handleFileReset = () => {
	// 		if (fileRef.current) {
	// 			fileRef.current.value = ''; // Ref를 사용하여 파일 입력 필드 초기화
	// 		}
	// 	};
	// 	handleFileReset();
	// }, []);

	useEffect(() => {
		const loadStoredColors = async () => {
			const storedChromaRgb = localStorage.getItem('chromaRgb');
			if (storedChromaRgb) {
				try {
					const chromaRgbArray = JSON.parse(storedChromaRgb);
					if (Array.isArray(chromaRgbArray)) {
						setPickedColors(chromaRgbArray);
					} else {
						setPickedColors([]); // 데이터가 배열이 아닐 경우 빈 배열로 설정
					}
				} catch (e) {
					setPickedColors([]); // JSON 파싱에 실패할 경우 빈 배열로 설정
				}
			} else {
				setPickedColors([]); // 세션 스토리지에 데이터가 없을 경우 빈 배열로 설정
			}
		};
		loadStoredColors();
	}, []);

	const createThumbnail = (src: string) => {
		const width = window.innerWidth;
		const height = window.innerHeight;
		// 새로운 div 엘리먼트를 생성하고, 브라우저 창의 크기로 설정
		const conatiner = document.createElement('div');
		conatiner.style.width = width + 'px';
		conatiner.style.height = height + 'px';

		// Konva Stage를 생성, 캔버스 같은 역할
		const stage = new Konva.Stage({
			container: conatiner,
			width,
			height,
		});

		// konva Layer를 생성, Layer는 Stage 위에 그림을 그림
		const layer = new Konva.Layer();

		// 이미지 객체를 생성하고 src를 설정
		let imageObj = new Image();
		imageObj.src = src;
		// 이미지가 로드 되면 실행될 함수
		imageObj.onload = () => {
			// 이미지와 스테이지의 비율을 계산
			const imageAspectRatio = imageObj.width / imageObj.height;
			const stageAspectRatio = stage.width() / stage.height();

			let imgWidth, imgHeight;

			// If the image is wider (landscape)
			// 이미지가 가로로 긴 경우, 스테이지의 너비에 맞춰 크기를 조정합니다.
			if (imageAspectRatio > stageAspectRatio) {
				imgWidth = stage.width();
				imgHeight = imgWidth / imageAspectRatio;
			}
			// If the image is taller (portrait)
			// 이미지가 세로로 긴 경우, 스테이지의 높이에 맞춰 크기를 조정합니다.
			else {
				imgHeight = stage.height();
				imgWidth = imgHeight * imageAspectRatio;
			}

			// 새로운 Konva 이미지를 생성하고 설정된 크기와 이미지를 할당합니다
			const img = new Konva.Image({
				image: imageObj,
				// x: (stage.width() - imgWidth) / 2,
				// y: (stage.height() - imgHeight) / 2,
				x: 0,
				y: 0,
				width: imgWidth,
				height: imgHeight,
				name: 'image',
			});

			// 썸네일 크기를 정의합니다.
			const thumbnailSize = 200;

			// 썸네일을 위한 캔버스를 생성합니다.
			const thumbnailcanvas = document.createElement('canvas');
			thumbnailcanvas.width = thumbnailSize;
			thumbnailcanvas.height = thumbnailSize;

			// 썸네일을 생성하기 위해 원본 이미지의 중앙 부분을 추출합니다.
			const wideness = imgWidth > imgHeight ? imgHeight : imgWidth;
			const canvas = img.toCanvas({
				x: (img.width() - wideness) / 2,
				y: (img.height() - wideness) / 2,
				width: wideness,
				height: wideness,
			});

			// 썸네일 캔버스에 추출된 이미지 부분을 그립니다.
			const ctx = thumbnailcanvas.getContext('2d'); //**  원본 canvas의 이미지를 새 canvas 크기로 스케일링하여 그립니다.*/
			ctx!.drawImage(
				canvas,
				0,
				0,
				canvas.width,
				canvas.height,
				0,
				0,
				thumbnailSize,
				thumbnailSize,
			);

			// 생성된 썸네일을 Blob 형태로 변환하여 저장합니다.
			thumbnailcanvas.toBlob((blobData) => {
				setImageBlob(blobData);
			});

			// 사용이 끝난 Konva 객체들을 메모리에서 해제합니다.
			stage.destroy();
			layer.destroy();
			img.destroy();

			// stage.toBlob({
			//     x: 0,
			//     y: 0,
			//     width: imgWidth,
			//     height: imgWidth,
			//     callback: (blob) =>  setImageBlob(blob)
			//   }).then(() => {
			//     stage.destroy();
			//   })
		};
	};

	const handlePick = (event: PickEvent) => {
		setPrevPickedColorLength(pickedColors.length);

		const canvas = event.image;
		const context = canvas.getContext('2d');

		const flashLayer = document.querySelector(
			'.flash-layer',
		) as HTMLDivElement;

		if (flashLayer) {
			flashLayer.style.display = 'block';
			flashLayer.classList.add('flash-effect');
			setTimeout(() => {
				flashLayer.classList.remove('flash-effect');
				flashLayer.style.display = 'none';
			}, 500);
		}

		if (context) {
			const alpha = 255;
			const colors: Array<chroma.Color> = [];

			const imageData = context.getImageData(
				0,
				0,
				canvas.width,
				canvas.height,
			);

			for (let index = 0; index < imageData.data.length; index += 4) {
				if (imageData.data[index + 3] === alpha) {
					let color = chroma(
						imageData.data[index],
						imageData.data[index + 1],
						imageData.data[index + 2],
					);
					colors.push(color);
				}
			}

			const chromaRgb = chroma.average(colors, 'lab'); // LAB 바탕으로 평균값 계산
			const newColorHex = chromaRgb.hex();

			// 세션 스토리지에서 현재의 색상 배열을 불러오고, 유효하지 않다면 빈 배열을 사용
			const storedColors = localStorage.getItem('chromaRgb');
			let colorsArray;
			try {
				colorsArray = storedColors ? JSON.parse(storedColors) : [];
			} catch (e) {
				colorsArray = []; // 유효하지 않은 JSON 형식일 경우 빈 배열 사용
			}

			// 새 색상을 세션 스토리지에 저장 (기존 배열을 덮어씀)
			localStorage.setItem('chromaRgb', JSON.stringify([newColorHex]));

			setPickedColors([newColorHex]);

			//-- 여기서부터 post
			if (!imageBlob) {
				// TODO imageBlob이 null일 경우 오류처리
				return;
			}

			const formData: FormData = new FormData();

			let picker;

			const c = hexToRgb(newColorHex);
			const uuid = uuidv4();
			const r_val = c.r;
			const g_val = c.g;
			const b_val = c.b;

			if (newColorHex) {
				const c = hexToRgb(newColorHex);
				const uuid = uuidv4();
				const r_val = c.r;
				const g_val = c.g;
				const b_val = c.b;
				picker = {
					uuid: uuid,
					r_val: r_val,
					g_val: g_val,
					b_val: b_val,
				};
			}

			let latitude = 0.0,
				longitude = 0.0;
			const exifData = localStorage.getItem('exif');
			let parsedExifData: any = {};

			if (exifData) {
				try {
					parsedExifData = JSON.parse(exifData);
					latitude = parsedExifData.latitude || 0.0;
					longitude = parsedExifData.longitude || 0.0;
				} catch (error) {
					console.error('EXIF 데이터 파싱 오류:', error);
				}
			}

			formData.append('attach', imageBlob);

			const picker_json: PickerJson = {
				uuid: uuid,
				r: r_val,
				g: g_val,
				b: b_val,
				exif: {
					latitude: latitude,
					longitude: longitude,
					...parsedExifData, // 추가적인 EXIF 데이터를 포함
				},
			};

			// token이 존재하면 picker_json 객체에 추가합니다.
			if (token) {
				picker_json.token = token;
			}

			formData.append('request', JSON.stringify(picker_json));

			setIsLoading(true);

			axios.post('/picker/new', formData).then((response) => {
				const res = JSON.parse(response.data);
				if (res.resCode === 200) {
					const item = res.data.result[0];

					const hexVal = rgbToHex(
						item.req_color.rgb.r_val,
						item.req_color.rgb.g_val,
						item.req_color.rgb.b_val,
					);
					const colorInfo = {
						uuid: item.uuid,
						originHexVal: hexVal,
						hex: hexVal,
						rgb: hexToRgb(hexVal),
						name: item.res_color.name,
						tones: {
							tone: item.res_color.tone,
							season: item.res_color.season,
							detail: item.res_color.detail,
						},
						matchingRatio: item.matching_ratio_PC,
					};
					setColorInfo(colorInfo);
					localStorage.setItem(
						'res-ColorInfo',
						JSON.stringify(colorInfo),
					);

					setIsLoading(false);
					// setIsPickVisible(false);
				}
			});
		}
	};

	const showColorInfo = useCallback(
		(hexValue: string) => {
			const storedData = localStorage.getItem('res-ColorInfo');
			let colorInfos = [];
			if (storedData) {
				try {
					const parsedData = JSON.parse(storedData);
					if (
						!Array.isArray(parsedData) &&
						typeof parsedData === 'object'
					) {
						colorInfos = [parsedData];
					} else if (Array.isArray(parsedData)) {
						colorInfos = parsedData;
					}
				} catch (e) {
					console.error('Failed to parse stored data:', e);
				}
			}

			const formData = new FormData();
			const c = hexToRgb(hexValue);

			const uuid = uuidv4();
			const r_val = c.r;
			const g_val = c.g;
			const b_val = c.b;

			if (!imageBlob) {
				return; // 이미지없음
			}

			let latitude = 0.0,
				longitude = 0.0;
			const exifData = localStorage.getItem('exif');
			let parsedExifData: any = {};

			if (exifData) {
				try {
					parsedExifData = JSON.parse(exifData);
					latitude = parsedExifData.latitude || 0.0;
					longitude = parsedExifData.longitude || 0.0;
				} catch (error) {
					console.error('EXIF 데이터 파싱 오류:', error);
				}
			}

			// MIME 타입에 따라 파일 이름 지정
			let fileName = 'image.jpg'; // 기본 파일 이름
			if (imageBlob.type === 'image/png') {
				fileName = 'image.png';
			} else if (imageBlob.type === 'image/jpeg') {
				fileName = 'image.jpg';
			}

			// FormData에 Blob 데이터를 파일 이름과 함께 추가
			formData.append('attach', imageBlob, fileName);

			const picker_json: PickerJson = {
				uuid: uuid,
				r: r_val,
				g: g_val,
				b: b_val,
				exif: {
					latitude: latitude,
					longitude: longitude,
					...parsedExifData, // 추가적인 EXIF 데이터를 포함
				},
			};

			// token이 존재하면 picker_json 객체에 추가합니다.
			if (token) {
				picker_json.token = token;
			}

			formData.append('request', JSON.stringify(picker_json));

			setIsLoading(true);

			axios.post('/picker/new', formData).then((response) => {
				const res = JSON.parse(response.data);

				const item = res.data.response.rgb;
				const hexVal = rgbToHex(item.r, item.g, item.b);

				const rgbData = res.data.response.rgb;
				const hexColor = rgbToHex(
					parseInt(rgbData.r),
					parseInt(rgbData.g),
					parseInt(rgbData.b),
				);

				const colorInfo = {
					uuid: uuid,
					originHexVal: hexVal,
					hex: hexColor,
					rgb: hexToRgb(hexVal),
					name: res.data.response.name,
					tones: {
						tone: res.data.response.tone,
						season: res.data.response.season,
						detail: res.data.response.detail,
					},
					matchingRatio: res.data.response.ratio,
				};

				timerRef.current = setTimeout(() => {
					setColorInfo(colorInfo);
					localStorage.setItem(
						'res-ColorInfo',
						JSON.stringify(colorInfo),
					);
					setIsLoading(false);
					setPickedColors(['']);
				}, 3000);
			});
		},
		[imageBlob, pickedColors],
	);

	const handleColorSave = () => {
		const formData = new FormData();

		if (selected === '') {
			showNotification(t('main:category'));
			return;
		}

		const data = {
			uuid: colorInfo.uuid,
			// favorite: sliderValue.toString(),
			favorite: sliderValue,
			category: selected.toLowerCase(),
		};

		formData.append('request', JSON.stringify(data));

		axios
			.post('picker/save', formData)
			.then((response) => {
				// 서버 응답을 JSON 객체로 파싱
				try {
					const responseData = JSON.parse(response.data);
					// responseData에서 msg 내용 검사
					if (
						responseData.res &&
						responseData.res.msg === 'ALREADY MY COLOR PICK'
					) {
						showNotification(t('main:already-save'));
					} else {
						showNotification(t('main:save'));
					}
				} catch (error) {
					console.error('JSON 파싱 에러:', error); // JSON 파싱 실패 시 오류 메시지 출력
				}
			})
			.catch((error) => {
				console.error('Error:', error); // 오류 발생 시 콘솔에 오류 메시지 출력
			});
	};

	useEffect(() => {
		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, []);

	// 로그인 값이 있을 경우 분석하기 버튼 클식 시 실행하는 함수
	const analyze = useCallback((hexColor: string) => {
		setAnalyzeResult((curr) => {
			return {
				...curr,
				color: hexColor,
			};
		});

		const storedData = localStorage.getItem('res-ColorInfo');
		if (storedData) {
			const parsedData = JSON.parse(storedData);
			if (!parsedData.matchingRatio) {
				// matchingRatio가 없다면 서버에 분석 요청을 합니다.
				const formData: FormData = new FormData();
				const c = hexToRgb(hexColor); // hexToRgb 함수 필요
				const uuid = uuidv4();
				const r_val = c.r;
				const g_val = c.g;
				const b_val = c.b;

				if (!imageBlob) {
					// TODO imageBlob이 null일 경우 오류처리
					return;
				}

				let latitude = 0.0,
					longitude = 0.0;
				const exifData = localStorage.getItem('exif');
				let parsedExifData: any = {};

				if (exifData) {
					try {
						const parsedExifData = JSON.parse(exifData);
						latitude = parsedExifData.latitude || 0.0;
						longitude = parsedExifData.longitude || 0.0;
					} catch (error) {
						console.error('EXIF 데이터 파싱 오류:', error);
					}
				}

				formData.append('attach', imageBlob);

				const picker_json: PickerJson = {
					uuid: uuid,
					r: r_val,
					g: g_val,
					b: b_val,
					exif: {
						latitude: latitude,
						longitude: longitude,
						...parsedExifData, // 추가적인 EXIF 데이터를 포함
					},
				};

				// token이 존재하면 picker_json 객체에 추가합니다.
				if (token) {
					picker_json.token = token;
				}

				formData.append('request', JSON.stringify(picker_json));

				setIsLoading(true);

				axios.post('/picker/new', formData).then((response) => {
					const res = JSON.parse(response.data);

					const item = res.data.response.rgb;
					const hexVal = rgbToHex(item.r, item.g, item.b);
					//
					const rgbData = res.data.response.rgb;
					const hexColor = rgbToHex(
						parseInt(rgbData.r),
						parseInt(rgbData.g),
						parseInt(rgbData.b),
					);

					const colorInfo = {
						uuid: uuid,
						originHexVal: hexVal,
						// 기존 hex: hexVal,
						// res 배경색에 적용
						hex: hexColor,
						rgb: hexToRgb(hexVal),
						name: res.data.response.name,
						tones: {
							tone: res.data.response.tone,
							season: res.data.response.season,
							detail: res.data.response.detail,
						},
						// matchingRatio: item.matching_ratio_PC,
						matchingRatio: res.data.response.ratio,
					};

					timerRef.current = setTimeout(() => {
						setColorInfo(colorInfo);
						localStorage.setItem(
							'res-ColorInfo',
							JSON.stringify(colorInfo),
						);
						setIsLoading(false);
						setPickedColors(['']);
						setOpen((curr) => !curr);
					}, 3000);
				});
			} else {
				setOpen((curr) => !curr);
			}
		}
	}, []);

	// 바텀시트 분석하기 배경색 설정
	const setBackgroundColor = useCallback(() => {
		const overlay = document.querySelector(
			'[data-rsbs-overlay]',
		) as HTMLDivElement;
		if (overlay) {
			// 원본 overlay.style.backgroundColor = analyzeResult.color;
			overlay.style.backgroundColor = colorInfo.hex;
		}
	}, [analyzeResult]);

	// 나와의 컬러 매칭률 % btn 클릭시 bottomsheet 내용
	const bottomsheetBody = useMemo(() => {
		if (!colorInfo) {
			return;
		}

		// 색상 반전 검정 or 화이트
		const oppositeColor = textColorFromBg(colorInfo.hex);

		const boxColor = (hexColor: string): string => {
			const { r, g, b }: RGB = hexToRgb(hexColor);

			if (yiqColor(r, g, b) > 128) {
				return '#bababa';
			}
			return '#d2cdcd';
		};

		const props: SvgIconProps = {
			color: colorInfo.hex,
			width: 32,
			height: 32,
			className: 'm-auto',
		};

		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					height: 'auto',
					padding: '20px',
					// 추가함 0618
					backgroundColor: colorInfo.hex,
					color: oppositeColor,
				}}
			>
				<h3 className="text-center">{t('main:matchingRatio')}</h3>
				<h2 className="match-percent">
					{/** 매칭률 */}
					{colorInfo.matchingRatio}
					<span className="text-lg">%</span>
				</h2>
				<p className="text-center">
					{/** 색상 정보 */}
					<span>
						{colorInfo && colorInfo.name
							? colorInfo.name.toUpperCase()
							: 'DEFAULT VALUE'}
					</span>
				</p>

				{/** 설명: 시각화 */}
				<div
					className="flex justify-between w-full my-3 p-8"
					style={{
						borderRadius: '25px',
						// backgroundColor: boxColor(colorInfo.hex),
						backgroundColor: '#FFF',
					}}
				>
					{/**첫번째:톤이름*/}
					<div className="text-center w-[30%]">
						<SvgIcThermo color={colorInfo.hex} className="m-auto" />
						<div style={{ color: '#666666' }}>
							{t(`main:${colorInfo.tones.tone}`)}
						</div>
					</div>
					{/**2번째:계절*/}
					<div className="text-center w-[30%]">
						{icSeasonal(colorInfo.tones.season, props)}
						<div style={{ color: '#666666' }}>
							{t(`main:${colorInfo.tones.season}`)}
						</div>
					</div>
					{/**3번째 디테일*/}
					<div className="text-center w-[30%]">
						<SvgIcBrightness
							color={colorInfo.hex}
							className="d-block m-auto"
						/>
						<div style={{ color: '#666666' }}>
							{detailName(colorInfo.tones.detail)}
						</div>
					</div>
				</div>
				{/** 선호도, 카테고리 인풋  */}
				<div
					className="flex flex-col justify-between w-full px-5 py-3 mb-3"
					style={{
						borderRadius: '25px',
						// backgroundColor: boxColor(colorInfo.hex),
						backgroundColor: '#FFF',
					}}
				>
					<span className="favor-text">Favorites</span>
					<div className="chart-header-menu-container-pick">
						<div className="w-full">
							<input
								type="range"
								min="0"
								max="100"
								value={sliderValue}
								onChange={handleSliderChange}
								className="slider"
								style={sliderStyle}
							/>
							<div className="value-display">
								<span className="preference-text">Hate</span>
								<span style={{ color: '#4430BD' }}>
									{sliderValue}
								</span>
								<span className="preference-text">Like it</span>
							</div>
						</div>
					</div>
				</div>

				{/* 카테고리 선택 영역 */}
				<div
					className="flex flex-col justify-between w-full px-5 py-3 "
					style={{
						borderRadius: '25px',
						// backgroundColor: boxColor(colorInfo.hex),
						backgroundColor: '#FFF',
					}}
				>
					<span className="favor-text">Category</span>
					<div className="grid-container">
						{categories.map((category) => (
							<div
								key={category.name}
								className={`category-item ${
									selected === category.name ? 'selected' : ''
								}`}
								onClick={() => handleSelect(category.name)}
							>
								<img
									src={category.icon}
									alt={category.name}
									style={{
										// filter:
										// 	selected === category.name
										// 		? 'none'
										// 		: 'grayscale(100%)',
										transition: 'filter 0.3s',
										width: '32px',
										height: '32px',
									}}
								/>
								<span
									className="mt-2 text-xs"
									style={{
										color:
											selected === category.name
												? '#4430BD'
												: '#9FA2B5',
										fontWeight:
											selected === category.name
												? '700'
												: '400',
									}}
								>
									{category.name}
								</span>
							</div>
						))}
					</div>
				</div>

				{/* 컬러 팔레트 저장 버튼 */}
				<button
					onClick={handleColorSave}
					className="text-center mx-auto p-4 rounded-full bg-black text-white shadow-lg w-full"
					style={{
						boxShadow: '#7a7a7a 0px 0px 24px -4px',
						marginTop: '5vw',
					}}
				>
					<img src={PalettePng} alt="Palette" className="inline" />{' '}
					{t('main:addIntoMyPalette')}
				</button>
				{notification && (
					<div className="fixed top-0 left-0 z-10 w-full h-full bg-black bg-opacity-50 flex items-center justify-center p-4">
						<div className="notification">{notification}</div>
					</div>
				)}
			</div>
		);
	}, [colorInfo, sliderValue, selected, notification]);

	const removeColor = useCallback(
		(hexColor: string) => {
			const storedData = localStorage.getItem('res-ColorInfo');
			let colorInfos = [];
			if (storedData) {
				const parsedData = JSON.parse(storedData);
				// 파싱된 데이터가 배열인지 확인합니다.
				if (Array.isArray(parsedData)) {
					colorInfos = parsedData;
				}
			}

			// 삭제할 색상의 hexColor를 기준으로 항목 찾기
			const colorIndex = colorInfos.findIndex(
				(info) => info.originHexVal === hexColor,
			);

			// 데이터에서 해당 색상 제거
			if (colorIndex > -1) {
				colorInfos.splice(colorIndex, 1);
				localStorage.setItem(
					'res-ColorInfo',
					JSON.stringify(colorInfos),
				);
			}

			setPickedColors((prev) => {
				setColorInfo(defaultColorInfo);
				setColorPaletteInfoActive(false);
				setPrevPickedColorLength(prev.length);

				// 세션 스토리지에서 chromaRgb 배열을 가져옵니다.
				const storedChromaRgb = localStorage.getItem('chromaRgb');
				const chromaRgbArray = storedChromaRgb
					? JSON.parse(storedChromaRgb)
					: [];

				// 배열에서 지정된 색상을 찾아 제거합니다.
				const index = chromaRgbArray.findIndex(
					(value: any) => value === hexColor,
				);
				if (index > -1) {
					chromaRgbArray.splice(index, 1);
					// 수정된 배열을 세션 스토리지에 저장.
					localStorage.setItem(
						'chromaRgb',
						JSON.stringify(chromaRgbArray),
					);
				}

				// pickedColors 배열 업데이트 . React의 상태 업데이트는 불변성을 유지해야 하기 때문에, 기존 배열을 직접 수정하는 것보다 새 배열을 생성
				const newPickedColors = prev.filter(
					(color) => color !== hexColor,
				);

				return newPickedColors;
			});
			// 직접함수 픽 로직 변경하면서 1개에 대한 세션만 사용해서 간단하게 추가
			localStorage.removeItem('res-ColorInfo');
		},
		[pickedColors],
	);

	// 새모양 나타나는 영역
	const pickedPalette = useMemo(() => {
		// bottomValue는 새 팔레트를 클릭 시 위치 조정을 위해 사용 됨, colorPaletteInfoActive 조건 시 160px 높이를 가진다. -> 2.26 현재 0
		// let bottomValue = colorPaletteInfoActive ? 160 : 0;

		return (
			<div
				id="pickedPalette"
				className="picked-palette"
				style={{ bottom: `${0}px` }}
			>
				{pickedColors
					.filter((hexColor) => hexColor !== '')
					.map((hexColor, index) => (
						<span
							onClick={() => {
								showColorInfo(hexColor);
							}}
							key={`bird${index}`}
							className={
								index === pickedColors.length - 1 &&
								prevPickedColorLength < pickedColors.length
									? 'flex flex-col items-center'
									: ''
							}
						>
							<div className="tooltip">{t('main:pick-bird')}</div>
							<div
								className={`${
									isBlinking ? 'blink-effect' : ''
								}`}
								style={{
									position: 'relative',
									display: 'inline-block',
								}}
							>
								<SvgHummingBird
									className="flex flex-col items-center"
									color={hexColor}
									scale={2.4}
								/>
								<div
									style={{
										position: 'absolute',
										top: 0,
										right: 0,
										transform: 'translate(9%, 3%)',
									}}
									onClick={(e) => {
										e.stopPropagation(); // 부모 요소로의 이벤트 전파를 방지
										removeColor(hexColor); // hexColor를 직접 전달합니다.
									}}
								>
									{/* 허밍버드 옆 삭제 x 위치 */}
									{/* <SvgCloseIcon color={hexColor} scale={1} /> */}
								</div>
							</div>
						</span>
					))}
			</div>
		);
	}, [pickedColors, colorPaletteInfoActive, isBlinking]);

	// 컬러픽시 나타나는 bottomSheet의 네모 영역 내용
	const colorPalette = useMemo(() => {
		if (colorInfo.hex === '') {
			return null;
		}

		setColorPaletteInfoActive(true);
		const textColor = textColorFromBg(colorInfo.hex);

		return (
			<div
				className="palette-info"
				style={{
					backgroundColor: colorInfo.hex,
					color: textColor,
				}}
			>
				<div className="flex justify-between w-full">
					<div className="w-full">
						<span className="color-name">
							{localStorage.getItem('res-ColorInfo') === null
								? ''
								: colorInfo && colorInfo.name // colorInfo와 colorInfo.name이 유효한지 확인
								? colorInfo.name.toUpperCase()
								: 'Not yet named'}
						</span>
						<div className="color-desc">
							<span className="flex">
								{/* 아이콘 */}
								{icSeasonal(colorInfo.tones.season, {
									className: 'inline-block mr-1',
									color: textColor,
								})}
							</span>
							{colorInfo.tones.season == '' ? (
								'Select a color and press the Pick button'
							) : (
								<span>
									{t(`main:${colorInfo.tones.tone}`)}
									&nbsp;&bull;&nbsp;
									{t(`main:${colorInfo.tones.season}`)}
									&nbsp;&bull;&nbsp;
									{detailName(colorInfo.tones.detail)}
								</span>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}, [colorInfo.hex]);

	// 로딩(새이미지)
	const loadingOverlay = useMemo(() => {
		if (!isLoading) {
			return null;
		}

		const style: React.CSSProperties = {
			position: 'absolute',
			left: `${touchPosition.x - 40}px`,
			top: `${touchPosition.y + 4}px`,
			transform: 'translate(-50%, -50%)',
		};

		return (
			<div className="loading-overlay">
				<div style={style}>
					<Lottie
						options={{
							loop: true,
							autoplay: true,
							animationData: animationData,
						}}
						isClickToPauseDisabled
						width={200}
						height={200}
					></Lottie>
				</div>
			</div>
		);
	}, [isLoading, touchPosition, animationData]);

	return (
		<>
			{loadingOverlay}
			<div className="flash-layer"></div>
			<HuePickColorPicker
				src={imageSrc as string}
				onPick={(e) => handlePick(e)}
				// setIsPickVisible={setIsPickVisible}
				setPickedColors={setPickedColors}
				setColorInfo={setColorInfo}
				setTouchPosition={setTouchPosition}
			/>
			{pickedPalette}
			<div
				className="palette-layer"
				style={{
					visibility: colorInfo.hex === '' ? 'hidden' : 'visible',
				}}
			>
				{localStorage.getItem('res-ColorInfo') === null ? (
					''
				) : (
					<div className="palette-info-button-layer">
						<button
							className="analyze-button flex items-center content-center"
							onClick={() => {
								// 로그인 되어 있다면
								const formData = new FormData();

								if (token) {
									formData.append(
										'request',
										JSON.stringify({ token }),
									); // 'request' 키로 토큰을 JSON 형식으로 포함

									axios
										.post(
											'https://api.huepick.net/member/status',
											formData,
										)
										.then((response) => {
											// response.data가 문자열인 경우 객체로 파싱
											const data =
												typeof response.data ===
												'string'
													? JSON.parse(response.data)
													: response.data;
											const profile = data.profile; // profile 데이터 접근 시도

											// profile 객체가 존재하는지 확인
											if (profile) {
												// 생일 값이 null인 경우 뉴비 상태로 정의
												const isBirthNull =
													profile.birth === null;

												// 뉴비 상태에 따른 페이지 이동 처리
												if (isBirthNull) {
													navigate('/signup');
												} else {
													analyze(colorInfo.hex);
												}
											} else {
												// profile 데이터가 없는 경우의 처리
												console.error(
													'Profile data is missing',
												);
												// navigate('/signin');
												setIsModal(true);
											}
										})
										.catch((error) => {
											console.error(
												'Error processing the response:',
												error,
											);
										});
								}
								// 로그인되어 있지 않다면
								else {
									// navigate('/signin');
									setIsModal(true);
								}
							}}
						>
							{t('main:analyze')}
						</button>
					</div>
				)}

				{/** 컬러픽시 나타나는 bottomSheet의 네모 영역 내용  */}
				{colorPalette}

				{/* 분석하기 버튼 클릭시 아래서 올라오는 모달  */}
				<BottomSheet
					open={open}
					ref={sheetRef}
					onDismiss={() => setOpen(false)}
					onSpringStart={setBackgroundColor}
					children={bottomsheetBody}
				/>

				{isModal && (
					<PublicModal
						handleOffModal={handleOffModal}
						title={t('main:need-login')}
						message={t('main:need-login-service')}
						onConfirm={handleRedirect}
					/>
				)}
			</div>
		</>
	);
}

export default ColorPicker;

import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useEmblaCarousel from 'embla-carousel-react'; // hook
import { useSetRecoilState } from 'recoil';
import { headerOptionsState } from '../atoms/appstate';
import { useTranslation } from 'react-i18next';
// import { convertImageFileToBase64 } from '../libs/image';
import BrowserModal from '../components/modal/BrowserModal';
import exifr from 'exifr';
import { isDesktop } from 'react-device-detect';
import '../styles/ImageLoader.css';
// 슬라이드 이미지 2
import cameraPng from '../assets/imgs/camera.png';
import galleryPng from '../assets/imgs/gallery.png';
// 하단 svg 이미지 2
import cameraIcon from '../assets/icons/img_photo.svg';
import galleryIcon from '../assets/icons/img_gallery.svg';
import flowerIcon from '../assets/icons/ic_flower.png';
// JS 함수
import { camera } from './app.android';

function ImageLoader() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });
	const [selectedIndex, setSelectedIndex] = useState(0);
	const slideImg = [cameraPng, galleryPng];
	const slideText = [t('main:camera-word'), t('main:gallery-word')];
	const [isUserDisplay, setIsUserDisplay] = useState<Boolean>(false);
	const setHeaderOptions = useSetRecoilState(headerOptionsState);
	const MAX_STORAGE_SIZE_MB = 3.5; // 3.5MB로 기준 설정

	const handleCameraCapture = async (imageData: string) => {
		setIsLoading(true);
		try {
			const exif = await exifr.parse(
				`data:image/jpeg;base64,${imageData}`,
			);

			localStorage.setItem(
				'blobImage',
				`data:image/jpeg;base64,${imageData}`,
			);
			if (exif) {
				localStorage.setItem('exif', JSON.stringify(exif, null, 2));
			}

			setIsLoading(false);
			navigate('/pick');
		} catch (error) {
			console.error('Error processing image:', error);
			setIsLoading(false);
		}
	};

	const openCamera = () => {
		camera.open = function () {
			navigator.camera.getPicture(
				function (imageData: string) {
					handleCameraCapture(imageData);
				},
				function (error: string) {
					console.error('Camera error:', error);
				},
				{
					quality: 50,
					destinationType: Camera.DestinationType.DATA_URL,
				},
			);
		};
		camera.open();
	};

	useEffect(() => {
		localStorage.removeItem('chromaRgb');
		localStorage.removeItem('imageBlob');
		localStorage.removeItem('blobImage');
		localStorage.removeItem('res-ColorInfo');
		localStorage.removeItem('exif');

		setHeaderOptions((prev) => ({
			...prev,
			showBackward: false,
			showHeader: true,
			template: () => <span className="header-title">HuePick</span>,
		}));

		// 테블릿이나 모바일이 아닌 데스크탑인 경우 페이지 이동
		if (process.env.NODE_ENV === 'production' && isDesktop === true) {
			alert(t('main:run'));
			window.location.href = 'https://huepick.world/';
			return;
		}

		const isModalDismissed = localStorage.getItem('showModal');
		if (!isModalDismissed) {
			setIsUserDisplay(true);
		} else {
			setIsUserDisplay(false);
		}

		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = 'unset';
		};
	}, [navigate]);

	useEffect(() => {
		if (!emblaApi) return;
		emblaApi.on('select', () =>
			setSelectedIndex(emblaApi.selectedScrollSnap()),
		);
	}, [emblaApi]);

	// 이미지를 로딩하고 압축 여부 결정
	const handleGalleryImage = async (imageData: string) => {
		setIsLoading(true);
		try {
			// Base64 데이터를 Blob으로 변환
			const blob = await fetch(
				`data:image/jpeg;base64,${imageData}`,
			).then((res) => res.blob());

			// 이미지 크기 계산 및 압축 여부 결정
			const compressedBase64 = await compressToFitStorageLimit(blob);

			// 압축된 Base64 데이터를 localStorage에 저장
			localStorage.setItem('blobImage', compressedBase64);

			setIsLoading(false);
			navigate('/pick');
		} catch (error) {
			console.error('Error processing gallery image:', error);
			setIsLoading(false);
		}
	};

	// Blob을 Base64로 변환하는 함수
	const convertBlobToBase64 = (blob: Blob): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				resolve(reader.result as string);
			};
			reader.onerror = (error) => {
				reject(error);
			};
		});
	};

	// Canvas를 사용하여 이미지 압축
	const compressImageWithCanvas = (
		blob: Blob,
		quality: number,
	): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				const img = new Image();
				img.src = reader.result as string;
				img.onload = () => {
					// Canvas 생성
					const canvas = document.createElement('canvas');
					const ctx = canvas.getContext('2d');

					// 이미지 크기 설정
					canvas.width = img.width;
					canvas.height = img.height;

					// Canvas에 이미지 그리기
					if (ctx) {
						ctx.drawImage(img, 0, 0);
						// 압축된 이미지를 Base64로 변환
						const compressedBase64 = canvas.toDataURL(
							'image/jpeg',
							quality,
						);
						resolve(compressedBase64);
					} else {
						reject('Failed to get canvas context');
					}
				};
				img.onerror = (error) => {
					reject(error);
				};
			};
			reader.onerror = (error) => {
				reject(error);
			};
		});
	};

	// 이미지 크기를 5MB 이하로 압축하는 함수
	const compressToFitStorageLimit = async (blob: Blob): Promise<string> => {
		let quality = 1.0; // 초기 압축률 (100%)
		let base64 = await convertBlobToBase64(blob);

		// Base64 문자열의 크기를 계산 (Base64는 약 33%의 크기 증가가 발생함)
		let base64SizeMB = (base64.length * (3 / 4)) / (1024 * 1024); // Base64는 4/3의 비율로 크기가 커짐

		// 이미지가 5MB 이상일 경우 압축
		while (base64SizeMB > MAX_STORAGE_SIZE_MB && quality > 0.1) {
			quality -= 0.1; // 압축률을 10%씩 줄임
			base64 = await compressImageWithCanvas(blob, quality);
			base64SizeMB = (base64.length * (3 / 4)) / (1024 * 1024);
		}

		if (base64SizeMB > MAX_STORAGE_SIZE_MB) {
			throw new Error('Failed to compress image below 5MB');
		}

		return base64;
	};

	// 갤러리에서 이미지 선택
	const openGallery = () => {
		navigator.camera.getPicture(
			(imageData) => {
				handleGalleryImage(imageData);
			},
			(error) => {
				console.error('Failed to pick image from gallery:', error);
			},
			{
				quality: 50,
				sourceType: Camera.PictureSourceType.SAVEDPHOTOALBUM,
				destinationType: Camera.DestinationType.DATA_URL,
			},
		);
	};

	const closeBrowserModal = () => {
		setIsUserDisplay(false);
	};

	// 다시 보지 않기 온클릭 이벤트
	const handleDismiss = () => {
		localStorage.setItem('showModal', 'false');
		setIsUserDisplay(false);
	};

	const loadingOverlay = useMemo(() => {
		if (!isLoading) {
			return null; // 아무것도 렌더링하지 않음
		}

		return (
			<div className="fixed top-0 left-0 right-0 bottom-0 z-10 bg-[#7c7b7b5d] flex items-center justify-center">
				<div className="loadingio-spinner-ellipsis-t8pkqbk3ttn">
					<div className="ldio-j17lc9b4yc">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			</div>
		);
	}, [isLoading]);

	return (
		<>
			{loadingOverlay}
			<div className="upload-bg h-screen flex flex-col justify-center items-center w-[100%]">
				{/* 에러 메시지 표시 */}
				<div className='className="w-full max-w-xs px-6 py-6 flex flex-col items-center bg-[#FFFFFF] shadow-lg rounded-[20px] relative mb-7'>
					<img src={flowerIcon} alt="icon" className="mb-2.5" />
					<span className="text-[16px] font-semibold leading-[26px] text-center mb-5">
						{t('main:camera')}
					</span>
					<button onClick={() => navigate('/signin')}>
						{t('main:go-login')}
					</button>
					<div className="overflow-hidden" ref={emblaRef}>
						<div className="flex">
							{slideImg.map((src, index) => (
								<div
									className="__slide flex flex-col items-center"
									key={index}
								>
									<span className="flex text-center justify-center text-[12px] text-[#666666] leading-[20px]"></span>
									<img
										src={src}
										alt={`Slide ${index + 1}`}
										className="mb-2.5"
									/>
									<span className="text-[14px] font-bold leading-[16.94px] text-[#4430BD] mb-2">
										{slideText[index]}
									</span>
								</div>
							))}
						</div>
						<div className="__buttons-container">
							<div>
								{slideImg.map((_, index) => (
									<div
										key={index}
										className={`__dot${
											index === selectedIndex
												? ' __dot--active'
												: ''
										}`}
										onClick={() =>
											emblaApi && emblaApi.scrollTo(index)
										}
									/>
								))}
							</div>
						</div>
					</div>
				</div>

				<div className="flex items-center">
					<div
						onClick={openCamera}
						className="flex text-center justify-center upload-box"
					>
						<img
							src={cameraIcon}
							alt="ic"
							style={{ width: '30px', height: '30px' }}
							className="mr-2"
						/>
						<h2 className="upload-header">
							{t('main:camera-shot')}
						</h2>
					</div>

					<span className="w-[1px] h-[18px] mx-[20px] opacity-[0.4] bg-[#FFFFFF] "></span>
					<div
						onClick={openGallery}
						className="flex text-center justify-center upload-box"
					>
						<img
							src={galleryIcon}
							alt="ic"
							style={{ width: '30px', height: '30px' }}
							className="mr-2"
						/>
						{/* <h2 className="upload-header">{t('main:upload')}</h2> */}
						{/* 아래 대신 사용하는 부분 */}
						<h2 className="upload-header">{t('main:upload')}</h2>
					</div>
				</div>
			</div>
			{/* ------------------모달 내용----------------- */}
			{isUserDisplay && (
				<BrowserModal
					onClose={closeBrowserModal}
					handleDismiss={handleDismiss}
				/>
			)}
		</>
	);
}

export default ImageLoader;

import React, { useState } from 'react';
import './PublicModal.css';
import { useTranslation } from 'react-i18next';

interface PublicModalProps {
	handleOffModal: () => void;
	title: string; // 타이틀을 위한 prop 추가
	message: string; // 메시지를 위한 prop 추가
	onConfirm?: () => void; // 선택적 콜백으로 확인 액션 정의
}

const PublicModal: React.FC<PublicModalProps> = ({
	handleOffModal,
	title,
	message,
	onConfirm,
}) => {
	const { t } = useTranslation();
	const handleConfirm = () => {
		if (onConfirm) {
			onConfirm(); // onConfirm이 정의되어 있다면 실행
		} else {
			handleOffModal(); // 정의되어 있지 않다면 모달 닫기 기본 동작 수행
		}
	};
	return (
		<div className="fixed top-0 left-0 z-10 w-full h-full bg-black bg-opacity-50 flex items-center justify-center p-4">
			<div className="w-full max-w-xs px-6 py-6 flex flex-col items-center bg-[#fff9eb] shadow-lg rounded-md relative">
				<div className="flex flex-col w-full">
					<span className="public-title">{title}</span>
					<span className="pubilc-text">{message}</span>
				</div>
				<button className="btn-pubilc-ok" onClick={handleConfirm}>
					{t('main:confirm')}
				</button>
			</div>
		</div>
	);
};

export default PublicModal;

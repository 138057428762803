import { useEffect, useState } from 'react';
import '../styles/ForgotPwd.css';
import { useSetRecoilState } from 'recoil';
import { headerOptionsState } from '../atoms/appstate';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import PublicModal from '../components/modal/PublicModal';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
	const { t } = useTranslation();
	const setHeaderOptions = useSetRecoilState(headerOptionsState);
	const { hash } = useParams();
	const navigate = useNavigate();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [isModal, setIsModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalMessage, setModalMessage] = useState('');
	const [shouldNavigate, setShouldNavigate] = useState(false);

	const handleOffModal = () => {
		setIsModal(false);
		if (shouldNavigate) {
			navigate('/signin');
		}
	};

	useEffect(() => {
		setHeaderOptions({
			showBackward: true,
			showHeader: true,
			template: () => (
				<span style={{ color: '#000000' }}>Reset Password</span>
			),
		});
	}, []);

	const handleResetPw = () => {
		if (password !== confirmPassword) {
			setModalMessage(t('main:check-password'));
			setIsModal(true);
		} else {
			const formData = new FormData();
			formData.append(
				'request',
				JSON.stringify({
					hashcode: hash,
					user_pw: password,
				}),
			);
			const postReset = async () => {
				try {
					const res = await axios.post(
						`https://api.huepick.net/member/passreset`,
						formData,
					);
					setModalMessage(t('main:change-password'));
					setShouldNavigate(true); // 이동해야 한다고 상태 설정
					setIsModal(true);
				} catch (err) {
					console.error(err);
					setModalMessage(t('main:error'));
					setIsModal(true);
				}
			};

			postReset();
		}
	};

	return (
		<div className="forgorPwd-bg flex items-center">
			<div className="forgorPwd-bg__container p-5 bg-white rounded-lg shadow-xl">
				<span className="block text-lg font-semibold">
					{t('main:reset-password')}
				</span>
				<p className="my-3">{t('main:new-password-confirm-button')}</p>
				<input
					type="password"
					className="mt-2 p-2 border rounded w-full"
					placeholder={t('main:new-password')}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<input
					type="password"
					className="mt-2 p-2 border rounded w-full"
					placeholder={t('main:new-password-confirm')}
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
				/>
				<button
					className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
					onClick={handleResetPw}
				>
					{t('main:confirm')}
				</button>
				<br />
			</div>
			{isModal && (
				<PublicModal
					handleOffModal={handleOffModal}
					title={modalTitle}
					message={modalMessage}
				/>
			)}
		</div>
	);
};

export default ResetPassword;

var app = {
	params: {
		os: 'android',
		update_today: null,
		user_uid: 0,
		user_id: 'guest',
		device_id: null,
		token_id: null,
		fcm_id: null,
		is_granted: false,
	},
	// Application Constructor
	initialize: function () {
		app.log('document ready script app.android.js');
		this.bindEvents();
	},
	// Bind Event Listeners
	//
	// Bind any events that are required on startup. Common events are:
	// 'load', 'deviceready', 'offline', and 'online'.
	bindEvents: function () {
		document.addEventListener('deviceready', this.onDeviceReady, false);
		document.addEventListener('backbutton', this.onBackKeyDown, false);
	},
	// deviceready Event Handler
	//
	// The scope of 'this' is the event. In order to call the 'receivedEvent'
	// function, we must explicitly call 'app.receivedEvent(...);'
	onDeviceReady: function () {
		fs.toast('APP READY');
		/*
		app.params.update_today = fs.env.app.today;
		app.params.user_uid = fs.env.app.user_uid;
		app.params.user_id = fs.env.app.user_id;

		app.params.device_id = storage.get('device_id');
		app.params.token_id = storage.get('token_id');
		app.params.fcm_id = storage.get('fcm_id');
		app.params.is_granted = storage.get('is_granted');

		var update_today = storage.get('update_today');
		if( !update_today || update_today != fs.env.app.today )
		{
			push.device_id();
			push.is_granted();
			push.get_token();
			push.get_id();
			setTimeout(function(){
				app.update();
			}, 3000);
		}

		FirebasePlugin.onMessageReceived(function(message) {
			if( message.messageType === "notification" )
			{
				if( message.notification_uri )
				{

					Swal.fire({
						title: message.notification_title,
						text: message.notification_body,
						showCancelButton: true,
						confirmButtonText: '내용확인',
						cancelButtonText: '닫기'
					}).then((result) => {
						if (result.isConfirmed)
						{
							fs.link(message.notification_uri);
						}
					});
				}else{
					fs.alert(message.notification_title, message.notification_body);
				}
			}
		});
		*/
	},
	login_status: function (user_uid) {
		$.ajax({
			url: '/member/auth/app_login_status',
			type: 'GET',
			data: { user_uid: user_uid, device_id: device.uuid },
			dataType: 'json', // xml, json, script, or html
			success: function (res) {},
			error: function (msg) {
				// app.log('error');
				// app.log(msg);
			},
			complete: function (res) {
				// app.log('complete log');
				// app.log(res.responseJSON);
			},
		});
	},
	update: function () {
		app.log('update start');
		var param = app.params;
		$.ajax({
			type: 'POST',
			url: '/firebase/update',
			data: param,
			timeout: 5000,
			dataType: 'json', // xml, json, script, or html
			success: function (res) {
				if (res.code == 200) {
					storage.set('update_today', app.params.update_today);
					storage.set('user_uid', app.params.user_uid);
					storage.set('user_id', app.params.user_id);
					storage.set('device_id', app.params.device_id);
					storage.set('token_id', app.params.token_id);
					storage.set('fcm_id', app.params.fcm_id);
					storage.set('is_granted', app.params.is_granted);
					app.log('update completed.');
				}
			},
		});
	},
	onBackKeyDown: function () {
		if (fs.env.path.page == 'main') {
			fs.confirm(close_app, '종료안내', '앱을 종료하시겠습니까?');
		} else {
			if ($('.modal').hasClass('in')) {
				fs.modal_close();
			} else {
				fs.back();
			}
		}
	},
	receivedEvent: function (id) {
		app.log('Received Event: ' + id);
	},
	log: function (text) {
		// $('.debug-area').text('');
		$('.debug-area').append(text + '\n');
	},
	debug_mode: function (method) {
		switch (method) {
			case 'text':
				storage.set('debug_mode', 'text');
				app.params.debug_mode = 'text';
				console.log('DEBUG MODE : TEXT');
				break;
			case 'line':
				storage.set('debug_mode', 'line');
				app.params.debug_mode = 'line';
				console.log('DEBUG MODE : LINE');
				break;
			case 'console':
				storage.set('debug_mode', 'console');
				app.params.debug_mode = 'console';
				console.log('DEBUG MODE : CONSOLE');
				break;
			case 'on':
				storage.set('is_debug', 'Y');
				app.params.is_debug = 'Y';
				console.log('DEBUG ON');
				break;
			case 'off':
				storage.set('is_debug', 'N');
				app.params.is_debug = 'N';
				console.log('DEBUG OFF');
				break;
		}
	},
	debug: function (text) {
		$('.debug-area').append(text);
		$('.debug-area').append('\n');
		return;

		if (app.params.is_debug != 'Y') return;

		switch (app.params.debug_mode) {
			case 'text':
				$('.debug-area').append(text);
				$('.debug-area').append('\n');
				break;
			case 'line':
				var object = '<li><p>' + text + '</p></li>';
				$('.system-line').append(object);
				break;
			case 'console':
				console.log(text);
				break;
		}
	},
};

var push = {
	param: { channel_version: '1.3' },

	device_id: function () {
		var device_id = storage.get('device_id');
		if (!device_id) device_id = device.uuid;
		app.params.device_id = device_id;
	},
	is_granted: function () {
		FirebasePlugin.hasPermission(
			function (hasPermission) {
				app.log(
					'Permission is ' + (hasPermission ? 'granted.' : 'denied.'),
				);
				if (hasPermission) app.params.is_granted = true;
				else app.params.is_granted = false;
			},
			function (e) {
				app.params.is_granted = false;
			},
		);
	},
	get_token: function () {
		FirebasePlugin.getToken(
			function (fcmToken) {
				app.params.token_id = fcmToken;
			},
			function (error) {
				app.params.token_id = false;
			},
		);
	},
	get_id: function () {
		FirebasePlugin.getId(
			function (appInstanceId) {
				app.params.fcm_id = appInstanceId;
			},
			function (error) {
				app.params.fcm_id = false;
			},
		);
	},
	channel_update: function (channels) {
		// DELETE CHANNELS
		if (channels) {
			for (var i = 0; i < channels.length; i++) {
				push.delete_channel(channels[i].id);
			}
		}

		$.ajax({
			async: false,
			type: 'POST',
			url: '/firebase/channels',
			timeout: 10,
			dataType: 'json', // xml, json, script, or html
			success: function (res) {
				if (res.code == 200) {
					$.each(res.channels, function (index, item) {
						var channel = {
							id: item.ch_id,
							name: item.ch_name,
							description: item.ch_description,
							sound: item.ch_sound,
							vibration: item.vibration,
							light: item.light,
							lightColor: item.lightColor,
							importance: item.importance,
							badge: item.badge,
							visibility: item.visibility,
						};
						if (item.ch_default == 'Y') {
							push.default_channel(channel);
						} else {
							push.create_channel(channel);
						}
					});
					storage.set('channel_version', push.param.channel_version);
					app.params.channel_version = push.param.channel_version;
				}
			},
			error: function (msg) {},
		});
	},
	channels: function () {
		FirebasePlugin.listChannels(
			function (channels) {
				if (typeof channels == 'undefined') {
					storage.set('channel_version', 'unsupported');
					app.log('channel unsupported');
					return;
				}
				if (channels.length > 0) push.channel_update(channels);
				else push.channel_update(false);
			},
			function (error) {
				storage.set('channel_version', 'unsupported');
			},
		);
	},
	default_channel: function (channel) {
		FirebasePlugin.setDefaultChannel(channel);
	},
	create_channel: function (channel) {
		FirebasePlugin.createChannel(channel);
	},
	delete_channel: function (channel) {
		FirebasePlugin.deleteChannel(channel);
	},
};

var storage = {
	available: function (type) {
		var storage;
		try {
			storage = window[type];
			var x = '__storage_test__';
			storage.setItem(x, x);
			storage.removeItem(x);
			return true;
		} catch (e) {
			return (
				e instanceof DOMException &&
				// Firefox를 제외한 모든 브라우저
				(e.code === 22 ||
					// Firefox
					e.code === 1014 ||
					// 코드가 존재하지 않을 수도 있기 떄문에 이름 필드도 확인합니다.
					// Firefox를 제외한 모든 브라우저
					e.name === 'QuotaExceededError' ||
					// Firefox
					e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
				// 이미 저장된 것이있는 경우에만 QuotaExceededError를 확인하십시오.
				storage &&
				storage.length !== 0
			);
		}
	},
	isLocalStorage: function () {
		if (this.available('localStorage')) return true;
		else return false;
	},
	isSessionStorage: function () {
		if (this.available('sessionStorage')) return true;
		else return false;
	},
	get: function (item) {
		var value = localStorage.getItem(item);
		return value;
	},
	set: function (item, value) {
		localStorage.setItem(item, value);
	},
	all: function () {
		var values = [],
			keys = Object.keys(localStorage),
			i = keys.length;
		while (i--) {
			values.push(localStorage.getItem(keys[i]));
		}
		return values;
	},
	remove: function (item) {
		localStorage.removeItem(item);
	},
	clear: function () {
		localStorage.clear();
	},
};

var session = {
	available: function (type) {
		var storage;
		try {
			storage = window[type];
			var x = '__storage_test__';
			storage.setItem(x, x);
			storage.removeItem(x);
			return true;
		} catch (e) {
			return (
				e instanceof DOMException &&
				// Firefox를 제외한 모든 브라우저
				(e.code === 22 ||
					// Firefox
					e.code === 1014 ||
					// 코드가 존재하지 않을 수도 있기 떄문에 이름 필드도 확인합니다.
					// Firefox를 제외한 모든 브라우저
					e.name === 'QuotaExceededError' ||
					// Firefox
					e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
				// 이미 저장된 것이있는 경우에만 QuotaExceededError를 확인하십시오.
				storage &&
				storage.length !== 0
			);
		}
	},
	isSessionStorage: function () {
		if (this.available('sessionStorage')) return true;
		else return false;
	},
	get: function (item) {
		var value = sessionStorage.getItem(item);
		return value;
	},
	all: function () {
		var values = [],
			keys = Object.keys(sessionStorage),
			i = keys.length;
		while (i--) {
			values.push(sessionStorage.getItem(keys[i]));
		}
		return values;
	},
	set: function (item, value) {
		sessionStorage.setItem(item, value);
	},
	remove: function (item) {
		sessionStorage.removeItem(item);
	},
	clear: function () {
		sessionStorage.clear();
	},
};

var spinner = {
	open: function () {
		var argv = this.open.arguments;
		var argc = this.open.arguments.length;
		var text = 0 < argc ? argv[0] : '로딩중입니다. 잠시만 기다려주세요!';
		var sec = 1 < argc ? argv[1] : 0;

		var options = { dimBackground: true };
		SpinnerPlugin.activityStart(text, options);

		if (sec) {
			sec = sec * 1000;
			setTimeout(function () {
				SpinnerPlugin.activityStop();
			}, sec);
		}
	},
	close: function () {
		SpinnerPlugin.activityStop();
	},
	test: function () {
		var argv = this.test.arguments;
		var argc = this.test.arguments.length;
		var text = 0 < argc ? argv[0] : '로딩중입니다. 잠시만 기다려주세요!';

		var options = { dimBackground: true };
		SpinnerPlugin.activityStart(text, options);

		setTimeout(function () {
			SpinnerPlugin.activityStop();
		}, 2000);
	},
};

var inapp = {
	open: function (uri) {
		var iab = cordova.InAppBrowser;
		iab.open(uri, '_blank', 'location=no'); // loads in the InAppBrowser, no location bar
		// iab.open(uri, '_blank');
	},
	system: function (uri) {
		var iab = cordova.InAppBrowser;
		iab.open(uri, '_system'); // open system browser
	},
	iab: function () {
		var iab = cordova.InAppBrowser;
		iab.open('file:///android_asset/www/index.html'); // loads in the Cordova WebView
		/*
		iab.open('local-url.html', '_self');         // loads in the Cordova WebView
		iab.open('local-url.html', '_system');       // Security error: system browser, but url will not load (iOS)
		iab.open('local-url.html', '_blank');        // loads in the InAppBrowser
		iab.open('local-url.html', 'random_string'); // loads in the InAppBrowser
		iab.open('local-url.html', 'random_string', 'location=no'); // loads in the InAppBrowser, no location bar
		*/
	},
};

export const camera = {
	open: function () {
		navigator.camera.getPicture(
			function (imageData) {
				console.log('Image Data:', imageData);

				$('#myImage').attr(
					'src',
					'data:image/jpeg;base64,' + imageData,
				);
			},
			function (message) {
				fs.alert('Failed because: ' + message);
			},
			{
				quality: 50,
				destinationType: Camera.DestinationType.DATA_URL, // base64 형식으로 받기
				// destinationType: Camera.DestinationType.FILE_URI // 안드로이드 기기의 저장된 파일 PATH 형식으로 받기
			},
		);
	},
	album: function () {
		navigator.camera.getPicture(
			function (imageData) {
				$('#myImage').attr(
					'src',
					'data:image/jpeg;base64,' + imageData,
				);
			},
			function (message) {
				fs.alert('Failed because: ' + message);
			},
			{
				quality: 50,
				sourceType: Camera.PictureSourceType.SAVEDPHOTOALBUM,
				destinationType: Camera.DestinationType.DATA_URL, // base64 형식으로 받기
			},
		);
	},
	photo: function () {
		navigator.camera.getPicture(
			function (imageData) {
				$('#myImage').attr(
					'src',
					'data:image/jpeg;base64,' + imageData,
				);
			},
			function (message) {
				fs.alert('Failed because: ' + message);
			},
			{
				quality: 50,
				sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
				destinationType: Camera.DestinationType.DATA_URL, // base64 형식으로 받기
			},
		);
	},
	crop: function () {
		navigator.camera.getPicture(
			function (imageData) {
				app.debug('Camera Get File Path : ' + imageData);
				/*Crop Image Plugin Code*/
				plugins.crop(
					function success(data) {
						app.debug('Crop Plugin File Path : ' + data);
						$('#myImage').attr('src', data);
					},
					function fail() {
						fs.alert('CROP FAILED');
					},
					imageData,
					{ quality: 100 },
				);
			},
			function (message) {
				fs.alert('Failed because: ' + message);
			},
			{
				quality: 50,
				destinationType: Camera.DestinationType.FILE_URI,
			},
		);
	},
	qrcode: function () {
		// plugin https://www.npmjs.com/package/cordova-plugin-qr-barcode-scanner
		// https://ko.qr-code-generator.com/
		cordova.plugins.barcodeScanner.scan(
			function (result) {
				if (result.cancelled) {
					fs.toast('바코드 스캐닝 취소!!');
				} else {
					// fs.toast('바코드 스캐닝 완료!!');
					var idx = result.text;
					//idx = idx.substr(7, idx.length);
					$('#scan-text').val(idx);

					// item.search('search');
					// app.debug('RESULT:'+result.text);
					// app.debug('FORMAT:'+result.format);
				}
			},
			function (error) {
				fs.toast('Scanning failed', error);
			},
			{
				preferFrontCamera: false, // 후면카메라(false), 전면카메라(true) (iOS and Android)
				showFlipCameraButton: true, // 전,후면 전환 버튼 표시 (iOS and Android)
				showTorchButton: true, // 후래쉬 On/Off 버튼 표시 (iOS and Android)
				torchOn: false, // 후래쉬 On/off, Android, launch with the torch switched on (if available)
				saveHistory: false, // Android, save scan history (default false)
				prompt: '스캔 영역 안에 바코드를 놓습니다', // Android 스캔 영역 안에 바코드를 놓습니다
				resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
				formats: 'QR_CODE,PDF_417', // default: all but PDF_417 and RSS_EXPANDED
				orientation: 'portrait', // Android only (portrait|landscape), default unset so it rotates with the device
				disableAnimations: true, // iOS
				disableSuccessBeep: true, // iOS and Android
			},
		);
	},
};

var ext = {
	device: function (method) {
		var res = null;

		switch (method) {
			case 'version':
				res = device.version;
				break;
			case 'model':
				res = device.model;
				break;
			case 'platform':
				res = device.platform;
				break;
			case 'uuid':
				res = device.uuid;
				break;
			case 'version':
				res = device.version;
				break;
			case 'manufacturer':
				res = device.manufacturer;
				break;
			case 'isVirtual':
				res = device.isVirtual;
				break;
			case 'serial':
				res = device.serial;
				break;
		}
		app.log('Device ' + method + ' : ' + res);
	},
	network: function () {
		app.log('Network Connected ' + navigator.connection.type);
	},
	locale: function () {
		var userLang = navigator.language || navigator.userLanguage;
		app.log('webview locale : ' + userLang);
		// en-US, ko-KR, zh-CN
	},
	app: function (method) {
		var res = null;
		switch (method) {
			case 'name':
				cordova.getAppVersion.getAppName().then(function (result) {
					app.log('APP NAME : ' + result);
				});
				break;
			case 'package':
				cordova.getAppVersion.getPackageName().then(function (result) {
					app.log('PACKAGE NAME : ' + result);
				});
				break;
			case 'ver':
			case 'version':
				cordova.getAppVersion
					.getVersionNumber()
					.then(function (result) {
						app.log('VERSION NUMBER : ' + result);
					});
				break;
			case 'code':
				cordova.getAppVersion.getVersionCode().then(function (result) {
					app.log('VERSION CODE : ' + result);
				});
				break;
		}
	},
	debug: function (text) {
		$('.debug-area').append(text);
		$('.debug-area').append('\n');
	},
};

function close_app() {
	navigator.app.exitApp();
}

import { useEffect, useState } from 'react';
import '../styles/ForgotPwd.css';
import { useSetRecoilState } from 'recoil';
import { headerOptionsState } from '../atoms/appstate';
import { useNavigate } from 'react-router-dom';
import PublicModal from '../components/modal/PublicModal';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const ForgotPwd = () => {
	const { t } = useTranslation();
	const setHeaderOptions = useSetRecoilState(headerOptionsState);
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [isModal, setIsModal] = useState(false);
	const [emailErrorModal, setEmailErrorModal] = useState(false);

	const handleOffModal = () => {
		setIsModal(false);
		setEmailErrorModal(false);
	};

	const isValidEmail = (email: string): boolean => {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	};

	const handleRedirect = () => {
		navigate('/signin');
	};

	const handlePostEmail = async () => {
		if (!email.trim() || !isValidEmail(email)) {
			setEmailErrorModal(true);
			return;
		}

		const formData = new FormData();
		formData.append('request', JSON.stringify({ user_id: email }));
		axios
			.post('https://api.huepick.net/member/forgot', formData)
			.then((response) => {
				console.log('response:', response.data);
				setIsModal(true);
			})
			.catch((error) => {
				console.error('err', error);
			});
	};

	useEffect(() => {
		setHeaderOptions({
			showBackward: true,
			showHeader: true,
			template: () => (
				<span style={{ color: '#000000' }}>Forgot Password</span>
			),
		});
	}, []);

	return (
		<div className="forgorPwd-bg flex items-center">
			<div className="forgorPwd-bg__container p-5 bg-white rounded-lg shadow-xl">
				<span className="block text-lg font-semibold">
					{t('main:problem-login')}
				</span>
				<p className="my-3">{t('main:reset-password-link')}</p>
				<input
					type="email"
					className="mt-2 p-2 border rounded w-full"
					placeholder={t('main:email-address')}
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<button
					onClick={handlePostEmail}
					className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
				>
					{t('main:send-login-link')}
				</button>
				<br />

				<div className="flex justify-center items-center">
					<span className="orxbar"></span>
					<span className="flex justify-center m-auto text-sm">
						or
					</span>
					<span className="orxbar"></span>
				</div>

				<span
					className="block mt-3 text-center text-gray-600"
					onClick={() => navigate('/createAccount')}
				>
					register
				</span>
			</div>
			{isModal && (
				<PublicModal
					handleOffModal={handleOffModal}
					title={t('main:send-email')}
					message={t('main:email-message')}
					onConfirm={handleRedirect}
				/>
			)}
			{emailErrorModal && (
				<PublicModal
					handleOffModal={handleOffModal}
					title={t('main:error')}
					message={t('main:invalid-email')}
				/>
			)}
		</div>
	);
};

export default ForgotPwd;
